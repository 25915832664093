<template>
 <div id="EditCartBeforeCheckout">
 <Navigation/>
  <EditCartBeforeCheckout/>
  <Partners/>
  <Footer/>
 </div>
</template>

<script>

import Partners from "../../home/components/Partners.vue";
import Footer from "../../home/components/Footer.vue";
import EditCartBeforeCheckout from "../components/EditCartBeforeCheckout.vue";
import Navigation from "../../home/components/Navigation.vue";
export default { components: { Partners, Footer, EditCartBeforeCheckout, Navigation } };
</script>

<style lang="scss" scoped>
</style>
